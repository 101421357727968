<template>
  <v-modal name="plan-dialog" @before-open="setPlan" height="auto" width="340">
    <v-card :loading="isLoading">
      <v-card-title class="headline" primary-title>
        {{ isNewPlan ? 'Csomag létrehozása' : 'Csomag szerkesztése' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field v-model="plan.name" label="Név" :rules="[requiredRule]"></v-text-field>
          <v-textarea v-model="plan.description" label="Leírás" :rules="[requiredRule]"></v-textarea>
          <v-text-field v-model="plan.pricePerPerson" label="Ár/Fő" :rules="[requiredRule]"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="closeDialog">Bezárás</v-btn>
        <v-btn text color="primary" :loading="isLoading" @click="submit">Mentés</v-btn>
      </v-card-actions>
    </v-card>
  </v-modal>
</template>
<script>
import { diff } from 'deep-object-diff'
import { UPDATE_PLAN, CREATE_PLAN } from '@/store/types'
import formControl from '@/mixins/formControl'

const INITIAL_STATE = {
  name: ''
}

export default {
  name: 'PlanDialog',
  mixins: [formControl],
  data() {
    return {
      plan: { ...INITIAL_STATE },
      editPlan: {},
      isLoading: false,
      isNewPlan: null
    }
  },
  methods: {
    setPlan(event) {
      this.isNewPlan = event.params.isNewPlan
      this.plan = event.params.plan ? JSON.parse(JSON.stringify({ ...event.params.plan })) : { ...INITIAL_STATE }

      if (!this.isNewPlan) {
        this.editPlan = JSON.parse(JSON.stringify({ ...event.params.plan }))
      }
    },
    closeDialog() {
      this.$modal.hide('plan-dialog')
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    },
    async savePlan() {
      this.toggleLoading()

      try {
        await this.$store.dispatch(CREATE_PLAN, this.plan)
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    async updatePlan() {
      this.toggleLoading()

      try {
        const planDiff = diff(this.editPlan, this.plan)
        await this.$store.dispatch(UPDATE_PLAN, { planId: this.editPlan.id, payload: planDiff })
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    submit() {
      this.validate()

      if (!this.isValid) {
        return
      }

      if (this.isNewPlan) {
        this.savePlan()
      } else {
        this.updatePlan()
      }
    }
  }
}
</script>

<style lang="scss">
.v-card__subtitle {
  padding: 0;
  font-weight: 500;

  .theme--light & {
    color: #111 !important;
  }
}
</style>
